import { BaseEntity } from './BaseEntity';

export enum CommentStatus {
  ENABLE = 'enable',
  DISABLE = 'disable'
}

export interface Comment extends BaseEntity {
  username: string
  video: string
  message: string
  likesCount: number
  status: CommentStatus
}

export type PartialComment = Partial<Comment>;
