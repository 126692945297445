
import { defineComponent, ref } from 'vue';
import { ElMessage } from 'element-plus';

import { useCommentsReports, useBanComment, useRejectReportComment } from '@/composables/api';
import { CommentIdOptions } from '@/services/api';

import { CommentStatus } from '@/interfaces/Comment';

import SearchBox from '@/components/keyword-searching/Index.vue';
import { useI18n } from 'vue-i18n';


export default defineComponent({
  components: {
    SearchBox
  },
  setup() {
    const page = ref(1);
    const keyword = ref();
    const sortBy = ref();
    const descSortBy = ref('updated_at');

    const { data, isLoading, isFetching, refetch } = useCommentsReports({ page, keyword, sortBy, descSortBy });
    const { isLoading: isBanLoading, mutate: banMutate } = useBanComment();
    const { isLoading: isRejectReportLoading, mutate: rejectMutate } = useRejectReportComment();

    const statusIndex = ref(undefined);
    const { t } = useI18n();


    const sortChange = ({ prop, order }) => {
      if (!prop) {
        descSortBy.value = '';
        sortBy.value = '';
        return;
      }

      if (order === 'ascending') {
        sortBy.value = prop;
        descSortBy.value = '';
      } else {
        descSortBy.value = prop;
        sortBy.value = '';
      }
    };

    const searchKeyword = (_keyword: string) => {
      keyword.value = _keyword;
    };
    const banComment = ({ commentId }: CommentIdOptions, index: Number) => {
      statusIndex.value = index;
      banMutate({ commentId }, {
        onSuccess() {
          refetch.value();

          ElMessage({
            type: 'success',
            message: 'Enable successfully'
          });
        },
        onError(error: any) {
          ElMessage({
            message: error.response?.data.message,
            type: 'error'
          });
        }
      });
    };

    const rejectReportComment = ({ commentId }: CommentIdOptions, index: Number) => {
      statusIndex.value = index;
      rejectMutate({ commentId }, {
        onSuccess() {
          refetch.value();

          ElMessage({
            type: 'success',
            message: 'Enable successfully'
          });
        },
        onError(error: any) {
          ElMessage({
            message: error.response?.data.message,
            type: 'error'
          });
        }
      });
    };

    return {
      page,
      data,
      isLoading,
      isFetching,
      statusIndex,
      isBanLoading,
      CommentStatus,
      isRejectReportLoading,
      sortChange,
      banComment,
      searchKeyword,
      t,
      rejectReportComment
    };
  }
});
